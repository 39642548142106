@import "var.conf";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/
@media print,
screen and (min-width: 768px) {
  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
    #Container{
      padding:0;
      background:none;
    }
    #MainImgTop{
      height:690px;
      overflow:hidden;
      position:relative;
      #MainImgInner{
        .bxslider{
          li{
            height:690px;
            width:100%;
            //min-width:1280px;
            background-image:url(../img/contents/top_slide_visuA.jpg);
            background-repeat:no-repeat;
            background-position:center center;
            background-size:cover;
            img{
              visibility:hidden;
              width:100%;
              height:auto;
              //min-width:1280px;
              //position:absolute;
              //top: 50%;
              //left: 50%;
              //transform: translateY(-50%) translateX(-50%);
            }
            &:nth-child(2){
              background-image:url(../img/contents/top_slide_visuB.jpg);
            }
            &:nth-child(3){
              background-image:url(../img/contents/top_slide_visuC.jpg);
            }
            &:nth-child(4){
              background-image:url(../img/contents/top_slide_visuD.jpg);
            }
            &:nth-child(5){
              background-image:url(../img/contents/top_slide_visuE.jpg);
            }
          }
        }
        .mainTxt{
          position:absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index:100;
          width:100%;
          .innerBasic{
            position:relative;
            &:before{
              width:532px;
              height:527px;
              content:"";
              display:block;
              position:absolute;
              top: -30px;
              left:-80px;
              background:url(../img/top_mainbg.png) no-repeat top left;
              z-index:100;
            }
          }
          .mainTxtInner{
            position:relative;
            z-index:200;
          }
          h2{
            font-family: 'Oswald', sans-serif;
            font-weight: 700;
            font-size:80px;
            line-height:90px;
            color:#fff;
            text-align:left;
            margin-top: -18px;
            letter-spacing: -2px;
            span{
              color:#ed1b2f;
            }
          }
          p{
            font-size:20px;
            font-weight:700;
            color:#fff;
            margin-top: 16px;
            margin-bottom: 34px;
            letter-spacing: 3px;
          }
          ul{
            li{
              a{
                width:300px;
                height:60px;
                line-height:60px;
                text-align:left;
                font-size:20px;
                font-family: 'Oswald', sans-serif;
                font-weight: 400;
                background-color: transparent;
                color:#fff;
                border-bottom: 1px solid #fff;
                &:before{
                  background: #fff;
                }
                span{
                  &:after{
                    content: "";
                    position: absolute;
                    display: block;
                    top: 50%;
                    right: 0;
                    margin-top: -9px;
                    width: 23px;
                    height: 18px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url(../img/arrow_dubble_w.png);
                    background-size: 23px auto;
                  }
                }
                &:hover{
                  animation: hover-04 .6s forwards;
                  opacity:1;
                  &:before{
                    animation: hover-in-01 .3s forwards alternate, hover-out-01 .3s .3s forwards alternate;
                  }
                  span{
                    &:after{
                      animation: hover-arrow-01_b .6s forwards;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    #Main{
      margin:0;
      border-top:5px solid #ed1b2f;
      #ContBox01{
        .big_Box{
          ul{
            li{
              figure,>div{
                height:460px;
                width:50%;
              }
              figure{
                float:right;
                background-image:url(../img/contents/top_cont_company.jpg);
                background-repeat:no-repeat;
                background-position:center center;
                background-size:cover;
                img{
                  display:none;
                }
              }
              >div{
                float:left;
                background-image:url(../img/cont_stripe_r.png);
                background-repeat:no-repeat;
                background-position:left bottom;
                background-size:187px auto;
                padding-top:80px;
                h3{
                  font-size:14px;
                  font-weight:700;
                  line-height:1;
                  letter-spacing: 1px;
                  margin-bottom: 25px;
                  &:after{
                    display:none;
                  }
                  span{
                    display: block;
                    margin-bottom: 10px;
                    font-family: "Oswald", sans-serif;
                    font-size: 40px;
                    font-weight: 700;
                    letter-spacing: 1px;
                  }
                }
                p{
                  text-align:center;
                }
                a{
                  margin:40px auto 0;
                  height:45px;
                  line-height:40px;
                  width:110px;
                  font-family: "Oswald", sans-serif;
                  font-size: 16px;
                  font-weight: 400;
                  padding-left:12px;
                  span{
                    &:after{
                      right: 19px;
                      top: 42%;
                    }
                  }
                }
              }
              &+li{
                figure{
                  float:left;
                  background-image:url(../img/contents/top_cont_service.jpg);
                }
                >div{
                  float:right;
                  background-color:#111111;
                  background-image:url(../img/cont_stripe_b.png);
                  background-position:right bottom;
                  background-size:187px auto;
                  h3{
                    color:#ccc;
                    span{
                      color:#fff;
                    }
                  }
                  p{
                    color:#ccc;
                  }
                  a{
                    border-bottom: 1px solid #fff;
                    color:#fff;
                    &:before{
                      background-color:#fff;
                    }
                    span{
                      &:after{
                        background-image: url(../img/img_arrow02.png);
                      }
                    }
                    &:hover{
                      animation: hover-04 .6s forwards;
                      opacity:1;
                      span{
                        &:after{
                          animation: hover-arrow-02_w .6s forwards;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      #ContBox02{
        background-color:#f0f0f0;
        padding:55px 30px 80px;
        >div{
          height:420px;
          width:100%;
          background:url(../img/contents/top_recruit.jpg) no-repeat center center;
          background-size:cover;
          position:relative;
          figure{
            display:none;
          }
          .innerBasic{
            position: absolute;
            bottom: -40px;
            left: 0;
            right: 0;
            margin: auto;
          }
          h4{
            font-family: "Oswald", sans-serif;
            font-size: 80px;
            font-weight: 700;
            color:#fff;
            line-height: 72px;
          }
          a.link_leftR02_r {
            >div {
              span {
                font-size: 20px;
              }
            }
          }
          a{
            width:410px;
            height:96px;
            line-height:96px;
            padding-left:25px;
            span{
            }
          }
        }
      }
    }
  }

  /*
  企業情報
  -------------------------------------*/
  #PageAbout.pageIndex {
    #Main{
      p{
        text-align:center;
        &+p{
          margin-top:32px;
        }
      }
      .aboutTopNav{
        margin-top:80px;
        li{
          width: 300px;
          float:left;
          &+li{
            margin-left:50px;
          }
          figure{
            margin-bottom:17px;
            width:100%;
            overflow:hidden;
            img{
              width:100%;
              height:auto;
              transition: all .5s ease-in-out 0s;
            }
          }
          div{
            display:block;
            position:relative;
            width:100%;
            height:54px;
            line-height:54px;
            font-size:16px;
            font-weight:700;
            background-color: transparent;
            border-bottom: 1px solid #000;
            transition: .3s;
            color:#333;
            text-decoration:none;
            padding-left:2px;
            opacity:1;
            &:before{
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: 0;
              content: '';
              background: #000;
              transform: scale(0, 1);
            }
            span{
              position: relative;
              z-index: 1;
              display: block;
              &:after{
                content: "";
                position: absolute;
                display: block;
                top: 50%;
                right: 0;
                margin-top: -4px;
                width: 9px;
                height: 13px;
                background-repeat: no-repeat;
                background-position: center center;
                background-image: url(../img/img_arrow01.png);
                background-size: 9px auto;
              }
            }
          }
          a{
            text-decoration:none;
            &:hover{
              opacity: 1;
              figure{
                img{
                  transform: scale(1.10);
                }
              }
              div{
                animation: hover-03 .6s forwards;
                opacity:1;
                &:before{
                  animation: hover-in-01 .3s forwards alternate, hover-out-01 .3s .3s forwards alternate;
                }
                span{
                  &:after{
                    animation: hover-arrow-02_b .6s forwards;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  社長挨拶
  -------------------------------------*/
  #PageAbout.pageMessageIndex {
    #Container {
      .innerBasic {
        #Main {
          #ContBox01 {
            p {
              width: 760px;
              margin-bottom: 34px;
              margin: 0 auto;
              letter-spacing: .8px;
              span {
                display: block;
              }
              &:nth-of-type(4) {
                line-height: 36px;
              }
            }
            .big_p {
              font-size: 20px;
              font-weight: 700;
            }
            .right_p {
              text-align: right;
            }
            ul {
              font-size: 0;
              letter-spacing: normal;
              margin-top: 91px;
              li {
                width: 300px;
                height: 50px;
                font-size: 16px;
                font-weight: 700;
                display: inline-block;
                a {
                  width: 100%;
                  height: 100%;
                  line-height: 50px;
                }
                &:first-of-type {
                  border-bottom: 1px solid #ed1b2f;
                  span {
                    color: #ed1b2f;
                    font-size: 16px;
                    line-height: 50px;
                  }
                }
                &:nth-of-type(2),&:nth-of-type(3) {
                  margin-left: 50px;
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  企業理念
  -------------------------------------*/
  #PageAbout.pageVisionIndex {
    #Container {
      .innerBasic {
        #Main {
          #ContBox01 {
            h3 {
              margin-bottom: 54px;
            }
            p {
              text-align: center;
              margin-bottom: 30px;
              span {
                display: block;
              }
            }
            >div {
              text-align: center;
              margin-top: -6px;
              img {
                width: 110px;
                display: inline-block;
                vertical-align: top;
              }
              dl {
                display: inline-block;
                vertical-align: top;
                text-align: left;
                font-weight: 700;
                font-size: 14px;
                line-height: 28px;
                margin-top: 21px;
                dt {
                  padding-left: 15px;
                }
                dd {
                  padding-left: 15px;
                }
              }
            }
          }
          #ContBox02 {
            margin-top: 82px;
            h3 {
              margin-bottom: 49px;
            }
            p {
              text-align: center;
              font-size: 24px;
              line-height:47px;
              letter-spacing: 3px;
              font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
              font-weight: 400;
              span {
                display: block;
              }
            }
            ul.aboutTopNav {
              margin-top: 88px;
              font-size: 0;
              letter-spacing: normal;
              li {
                width: 300px;
                height: 55px;
                font-weight: 700;
                font-size: 16px;
                display: inline-block;
                margin-left: 50px;
                a {
                  width: 100%;
                  height: 100%;
                  line-height: 55px;
                  span {
                  }
                }
                &:first-of-type {
                  margin-left: 0;
                }
                &:nth-of-type(2) {
                  >div {
                    line-height: 55px;
                    border-bottom: 1px solid #ed1b2f;
                    span {
                      color: #ed1b2f;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  会社概要
  -------------------------------------*/
  #PageAbout.pageCompanyIndex {
    #Container {
      .innerBasic {
        #Main {
          #ContBox01 {
            >div.box01 {
              width: 760px;
              margin: 0 auto;
              border-bottom: 1px solid #f0f0f0;
              padding-top: 10px;
              padding-bottom: 10px;
              &:first-of-type {
                border-top: 1px solid #f0f0f0;
                margin-top: 60px;
              }
              &:nth-of-type(4) {
                dd {
                  a {
                    text-decoration: none;
                    pointer-events:none;
                  }
                }
              }
              dl {
                font-size: 0;
                letter-spacing: normal;
                width: 760px;
                margin: 0 auto;
                border-left: 3px solid #333;
                dt {
                  font-size: 16px;
                  font-weight: 700;
                  display: inline-block;
                  width: 230px;
                  vertical-align: top;
                  padding-top: 13px;
                  padding-bottom: 13px;
                  padding-left: 28px;
                  margin-right: 7px;
                  letter-spacing: 1px;
                }
                dd {
                  font-size: 16px;
                  display: inline-block;
                  width: 520px;
                  padding-top: 13px;
                  padding-bottom: 13px;
                  letter-spacing: 1px;
                  ul {
                    li {
                      margin-bottom: 8px;
                      &:last-of-type {
                        margin-bottom: 0;
                      }
                    }
                  }
                  #Gmap01,
                  #Gmap02{
                    height:250px;
                    margin:15px 0 10px;
                  }
                  ul.txtLink {
                    margin-bottom: 30px;
                    li {
                      margin-bottom: 0;
                      font-size:14px;
                      text-align:right;
                      a{
                        color:#888;
                      }
                    }
                  }
                  ul.txtLink02 {
                    li {
                      margin-bottom: 0;
                      font-size:14px;
                      text-align:right;
                      a{
                        color:#888;
                      }
                    }
                  }
                }
              }
            }
          }
          #ContBox02 {
            margin-top: 81px;
            h3 {

            }
            dl {
              width: 760px;
              margin: 0 auto;
              font-size: 0;
              letter-spacing: normal;
              padding-top: 13px;
              padding-bottom: 13px;
              &:first-of-type {
                margin-top: 71px;
              }
              dt {
                width: 110px;
                font-size: 16px;
                font-weight: 700;
                display: inline-block;
                vertical-align: top;
                margin-right: 35px;
                letter-spacing: 1px;
              }
              dd {
                font-size: 16px;
                display: inline-block;
                vertical-align: top;
                letter-spacing: .7px;
                &:first-of-type {
                  width: 50px;
                  height: 24px;
                  position: relative;
                  margin-right: 15px;
                  &:after {
                    content: "";
                    position: absolute;
                    bottom: 12px;
                    left: 0;
                    right:0;
                    margin: auto;
                    display: block;
                    width: 50px;
                    height: 1px;
                    background: #333;
                  }
                }
                &:nth-of-type(2) {
                  width: 550px;
                  padding-left: 30px;
                }
              }
            }
            ul.aboutTopNav {
              margin-top: 84px;
              font-size: 0;
              letter-spacing: normal;
              li {
                width: 300px;
                height: 55px;
                font-weight: 700;
                font-size: 16px;
                display: inline-block;
                margin-left: 50px;
                a {
                  width: 100%;
                  height: 100%;
                  line-height: 55px;
                  span {
                  }
                }
                &:first-of-type {
                  margin-left: 0;
                }
                &:nth-of-type(3) {
                  >div {
                    line-height: 55px;
                    border-bottom: 1px solid #ed1b2f;
                    span {
                      color: #ed1b2f;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  事業案内
  -------------------------------------*/
  #PageService.pageIndex {
    #Container {
      padding-bottom: 130px;
      #Main {
        .contBox {  //ブロック共通設定
          h4 {
            text-align: center;
            font-size: 32px;
            font-weight: 700;
            letter-spacing: 5px;
            margin-top: 80px;
            margin-bottom: 35px;
            span {
              display: inline-block;
            }
            &:after {
              content: "";
              display: block;
              margin: 20px auto 0;
              width: 70px;
              height: 4px;
              background-color: #000;
            }
          }
          .click_sp {  //大きい画像のブロック
            position: relative;
            width: 1000px;
            margin: 0 auto;
            img {
              width: 50px;
              left: 0;
              right: 0;
              top: -85px;
              margin: auto;
              position: absolute;
            }
            h3 {
              font-size: 40px;
              letter-spacing: 6px;
              &:after {
                display: none;
              }
            }
          }
          .showBox_sp {
            width: 1000px;
            margin: 0 auto;
            >div {
              &:first-of-type {
                position: relative;
                img {
                  width: 1000px;
                }
                p {
                  font-size: 83px;
                  font-weight: 700;
                  font-family: 'Oswald', sans-serif;
                  text-transform: uppercase;
                  letter-spacing: -2px;
                  color: #ffffff;
                  position: absolute;
                  bottom: 21px;
                  left: 27px;
                  opacity: .9;
                  span {
                    margin-left: 10px;
                  }
                }
              }
            }
            figure {
              text-align: center;
              img {
                width: 760px;
                margin-top: 39px;
                margin-bottom: 24px;
              }
            }
            .aboutTopNav { //右移動リンクボタン設定
              text-align: center;
              width: 760px;
              margin: 0 auto;
              li {
                width: 355px;
                height: 95px;
                font-size: 16px;
                font-weight: 700;
                display: inline-block;
                border-bottom: 1px solid #000000;
                a.link_leftR02_r {
                  color: #333333;
                  background-color: transparent;
                  line-height: 95px;
                  &:before {
                    background: #cfcfcf;
                  }
                  &:hover {
                    animation: none;
                    span {
                      &:after {
                        -webkit-animation: hover-arrow-01_b02 .6s forwards;
                        animation: hover-arrow-01_b02 .6s forwards;
                      }
                    }
                  }
                  span {
                    padding-right: 37px;
                    text-align: right;
                    position: relative;
                    img {
                      position: absolute;
                      left: 0;
                      top: 28px;
                      vertical-align: baseline;
                    }
                    &:after {
                      background-image: url(../img/arrow_dubble_b.png);
                      right: 2px;
                      &:hover {

                      }
                    }
                  }
                  @keyframes hover-arrow-01_r {
                    0%, 100% {
                      background-image: url(../img/arrow_dubble_w.png);
                    }
                    50% {
                      background-image: url(../img/arrow_dubble_w.png);
                    }
                  }
                }
              }
            }
          }
        }
        #ContBox01 {
          padding-bottom: 89px;
          border-bottom: 1px solid #f0f0f0;
          p {
            width: 1000px;
            margin: 0 auto;
            font-size: 16px;
            text-align: center;
            span {
              display: block;
            }
          }
          >div.disp_pc {
            width: 1000px;
            margin: 0 auto;
            .aboutTopNav{
              margin-top:80px;
              li{
                width: 300px;
                float:left;
                &+li{
                  margin-left:50px;
                }
                figure{
                  margin-bottom:17px;
                  width:100%;
                  overflow:hidden;
                  img{
                    width:100%;
                    height:auto;
                    transition: all .5s ease-in-out 0s;
                  }
                }
                div{
                  display:block;
                  position:relative;
                  width:100%;
                  height:54px;
                  line-height:54px;
                  font-size:16px;
                  font-weight:700;
                  background-color: transparent;
                  border-bottom: 1px solid #000;
                  transition: .3s;
                  color:#333;
                  text-decoration:none;
                  padding-left:2px;
                  opacity:1;
                  &:before{
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 0;
                    content: '';
                    background: #000;
                    transform: scale(0, 1);
                  }
                  span{
                    position: relative;
                    z-index: 1;
                    display: block;
                    &:after{
                      content: "";
                      position: absolute;
                      display: block;
                      top: 50%;
                      right: 0;
                      margin-top: -4px;
                      width: 9px;
                      height: 13px;
                      background-repeat: no-repeat;
                      background-position: center center;
                      background-image: url(../img/arrow_shita_b.png);
                      background-size: 9px auto;
                      &:hover {
                        background-image: url(../img/arrow_shita_w.png);
                      }
                    }
                  }
                }
                a{
                  text-decoration:none;
                  &:hover {
                    opacity: 1;
                    figure{
                      img{
                        transform: scale(1.10);
                      }
                    }
                    div{
                      // animation: hover .6s forwards;
                      -webkit-animation: hover-03 .6s forwards;
                      animation: hover-03 .6s forwards;
                      opacity:1;
                      &:before{
                        animation: hover-in .3s forwards alternate, hover-out .3s .3s forwards alternate;
                      }
                      span{
                        &:after{
                          // animation: hover-arrow-02_b .6s forwards;
                          -webkit-animation: hover-arrow-03 .6s forwards;
                          animation: hover-arrow-03 .6s forwards;
                        }
                      }
                      @keyframes hover {
                        0%, 100% {
                          color: #333;
                        }
                        50% {
                          color: #fff;
                        }
                      }
                      @keyframes hover-in {
                        0% {
                          transform-origin: left top;
                          transform: scale(1, 0);
                        }
                        100% {
                          transform-origin: left top;
                          transform: scale(1, 1);
                        }
                      }
                      @keyframes hover-out {
                        0% {
                          transform-origin: left bottom;
                          transform: scale(1, 1);
                        }
                        100% {
                          transform-origin: left bottom;
                          transform: scale(1, 0);
                        }
                      }
                      // @keyframes hover-03 {
                      //   0%, 100% {
                      //     background-image: url(../img/arrow_shita_b.png);
                      //     background-repeat: no-repeat;
                      //     background-position: right 1px center;
                      //     background-size: 9px auto;
                      //   }
                      //   50% {
                      //     background-image: url(../img/arrow_shita_w.png);
                      //     background-repeat: no-repeat;
                      //     background-position: right 1px center;
                      //     background-size: 9px auto;
                      //   }
                      // }
                    }
                  }
                }
                &:nth-of-type(4),&:nth-of-type(5) {
                  margin-top: 50px;
                }
                &:nth-of-type(4) {
                  margin-left: 175px;
                }
              }
            }
          }
        }
        #ContBox02 {
          margin-top: 146px;
          border-bottom: 1px solid #f0f0f0;
          padding-bottom: 72px;
          .click_sp {
            img {
              left: -48px;
            }
            h3 {

            }
          }
          .showBox_sp {
            >div {
              &:first-of-type {  //大きい画像ブロック
                img {

                }
                p {
                  span {

                  }
                }
              }
              &:nth-of-type(2) {  //中間文字ブロック
                ul {
                  width: 580px;
                  margin: 0 auto;
                  margin-top: 56px;
                  padding-left: 7px;
                  li {
                    font-size: 16px;
                    line-height: 16px;
                    font-weight: 700;
                    margin-bottom: 21px;
                    position: relative;
                    padding-left: 24px;
                    padding-top: 3px;
                    span {
                      font-size: 20px;
                      font-family: 'Oswald', sans-serif;
                      color: #e20c21;
                      margin-right: 14px;
                      position: absolute;
                      top: 0;
                      left: 0;
                    }
                  }
                }
              }
              &:nth-of-type(3) {  //下段ブロック
                dl {
                  font-size: 0;
                  letter-spacing: normal;
                  text-align: center;
                  width: 760px;
                  margin: 0 auto 16px;
                  dt {
                    text-align: left;
                    font-size: 16px;
                    width: 135px;
                    display: inline-block;
                    vertical-align: top;
                    margin-top: 5px;
                    font-weight: 700;
                    padding-top: 3px;
                    letter-spacing: 1px;
                  }
                  dd {
                    font-size: 16px;
                    width: 625px;
                    display: inline-block;
                    border-left: 1px solid #000000;
                    vertical-align: top;
                    margin-top: 5px;
                    padding-left: 15px;
                    text-align: left;
                    padding-top: 3px;
                    padding-bottom: 3px;
                    letter-spacing: .8px;
                    span {
                      display: block;
                      line-height: 31px;
                      margin-top: 4px;
                    }
                  }
                }
              }
            }
            figure {
              img {

              }
            }
          }
        }
        #ContBox03 {
          margin-top: 146px;
          border-bottom: 1px solid #f0f0f0;
          padding-bottom: 89px;
          .click_sp {
            img {
              left: -48px;
            }
          }
          .showBox_sp {
            >div {
              &:first-of-type {

              }
              &:nth-of-type(2) {
                p {
                  text-align: center;
                  line-height: 32px;
                  letter-spacing: .8px;
                  span {
                    display: block;
                  }
                  &:first-of-type {
                    margin-top: 52px;
                  }
                  &:nth-of-type(2) {
                    margin-top: 32px;
                  }
                  &:nth-of-type(3) {
                    margin-top: 3px;
                    margin-bottom: 12px;
                  }
                }
                >ul {
                  text-align: center;
                  margin-top: 2px;
                  li {
                    display: inline-block;
                    color: #e20c21;
                    font-size: 24px;
                    font-weight: 700;
                    span {
                      color: #333333;
                      padding-left: 10px;
                    }
                    &:nth-of-type(2),&:nth-of-type(3) {
                      margin-left: 29px;
                    }
                  }
                }
              }
              &:nth-of-type(3) {
                ul.aboutTopNav {
                  li {
                    border-bottom: 1px solid #000000;
                    a {
                      span {
                        position: relative;
                        text-align: right;
                        img {
                          position: absolute;
                          left: 0;
                          top: 32px;
                          vertical-align: baseline;
                        }
                      }
                    }
                    &:first-of-type {
                      a {
                        span {
                          img {
                            width: 140px;
                            top: 27px;
                          }
                        }
                      }
                    }
                    &:nth-of-type(2) {
                      margin-left: 44px;
                      a {
                        span {
                          img {
                            width: 80px;
                          }
                        }
                      }
                    }
                  }

                }
              }
              figure {

              }
            }
          }
        }
        #ContBox04 {
          margin-top: 147px;
          border-bottom: 1px solid #f0f0f0;
          padding-bottom: 89px;
          .click_sp {
            img {
              left: -47px;
            }
            h3 {
              margin-bottom: 47px;
            }
          }
          .showBox_sp {
            >div {
              &:nth-of-type(2) {
                >div {
                  text-align: center;
                  >ul {
                    text-align: left;
                    margin-top: 16px;
                    li {
                    font-size: 16px;
                    line-height: 16px;
                    font-weight: 700;
                    margin-bottom: 21px;
                    position: relative;
                    padding-left: 24px;
                    padding-top: 3px;
                      span {
                      font-size: 20px;
                      font-family: 'Oswald', sans-serif;
                      color: #e20c21;
                      margin-right: 14px;
                      position: absolute;
                      top: 0;
                      left: 0;
                      }
                    }
                    &:first-of-type {
                      display: inline-block;
                      vertical-align: top;
                      width: 320px;
                      margin-left: 10px;
                    }
                    &:nth-of-type(2) {
                      display: inline-block;
                      vertical-align: top;
                      width: 350px;
                    }
                  }
                }
              }
              &:nth-of-type(3) {
                ul.aboutTopNav {
                  li {
                    border-bottom: 1px solid #000000;
                    a {
                      span {
                        img {
                          width: 120px;
                          top: 28px;
                        }
                      }
                    }
                  }
                }
              }
            }
            figure {
              img {
                margin-top: 45px;
              }
            }
          }
        }
        #ContBox05 {
          margin-top: 146px;
          border-bottom: 1px solid #f0f0f0;
          padding-bottom: 90px;
          .click_sp {
            img {
              top: -87px;
              left: -48px;
            }
          }
          .showBox_sp {
            >div {
              &:nth-of-type(2) {
                ul {
                  width: 400px;
                  margin: 0 auto;
                  padding-left: 9px;
                  margin-top: 48px;
                  li {
                    font-size: 16px;
                    line-height: 16px;
                    font-weight: 700;
                    margin-bottom: 21px;
                    position: relative;
                    padding-left: 24px;
                    padding-top: 3px;
                    span {
                      font-size: 20px;
                      font-family: 'Oswald', sans-serif;
                      color: #e20c21;
                      margin-right: 14px;
                      position: absolute;
                      top: 0;
                      left: 0;
                    }
                  }
                }
              }
              &:nth-of-type(3) {
                ul.aboutTopNav {
                  li {
                    a {
                      span {
                        img {
                          width: 130px;
                        }
                      }
                    }
                  }
                }
              }
            }
            figure {

              img {
                margin-top: 46px;
              }
              p {
                width: 760px;
                margin: 0 auto;
                margin-bottom: 13px;
              }
            }
          }
        }
        #ContBox06 {
          margin-top: 147px;
          .click_sp {
            img {
              left: -47px;
            }
          }
          .showBox_sp {
            >div {
              &:nth-of-type(2) {
                p {
                  text-align: center;
                  letter-spacing: .8px;
                  span {
                    display: block;
                  }
                  &:first-of-type {
                    margin-top: 52px;
                  }
                  &:nth-of-type(2) {
                    margin-top: 32px;
                  }
                }
              }
              &:nth-of-type(3) {
                dl {
                  font-size: 0;
                  letter-spacing: normal;
                  width: 760px;
                  margin: 0 auto 16px;
                  dt {
                    text-align: left;
                    font-size: 16px;
                    width: 194px;
                    display: inline-block;
                    vertical-align: top;
                    margin-top: 5px;
                    font-weight: 700;
                    padding-top: 3px;
                    letter-spacing: 1px;
                  }
                  dd {
                    font-size: 16px;
                    width: 560px;
                    display: inline-block;
                    border-left: 1px solid #000000;
                    vertical-align: top;
                    margin-top: 5px;
                    padding-left: 15px;
                    text-align: left;
                    padding-top: 3px;
                    padding-bottom: 3px;
                    letter-spacing: .8px;
                    span {
                      display: block;
                      line-height: 31px;
                      margin-top: 4px;
                    }
                  }
                }
                ul {
                  &:first-of-type {
                    font-size: 0;
                    letter-spacing: normal;
                    text-align: center;
                    margin-top: 33px;
                    li {
                      width: 380px;
                      height: 80px;
                      border: 1px solid #888888;
                      display: inline-block;
                      font-size: 16px;
                      background-color:#fff;
                      background-repeat:no-repeat;
                      background-position:center center;
                      &:hover {
                        opacity: .6;
                      }
                      a {
                        width: 100%;
                        height: 100%;
                        line-height: 80px;
                        display: block;
                      }
                      &:first-of-type {
                        background-image: url(../img/img_sonpo.jpg);
                        background-size: 234px auto;
                      }
                      &:nth-of-type(2) {
                        background-image: url(../img/img_kaijou.jpg);
                        background-size: 185px auto;
                        border-left: none;
                      }
                    }
                  }
                }
                ul.aboutTopNav {
                  margin-top: 23px;
                  li {
                    a {
                      span {
                        img {
                          width: 165px;
                          top: 24px;
                        }
                      }
                    }
                  }
                }
              }
            }
            figure {
              img {
                margin-top: 49px;
              }
            }
          }
        }
      }


    }
  }

  /*
  採用情報
  -------------------------------------*/
  #PageRecruit.pageIndex {
    #Main{
      .aboutTopNav{
        margin-bottom:80px;
        li{
          width:300px;
          float:left;
          &+li{
            margin-left:50px;
          }
          a{
            padding:10px 0;
            font-weight:700;
            font-size:16px;
          }
        }
      }
      #ContBox01{
        p{
          text-align:center;
          &+p{
            margin-top:32px;
          }
        }
        .innerBasic{
          >div{
            text-align:center;
            margin-top:30px;
            figure{
              width:110px;
              height:110px;
              display:inline-block;
              vertical-align:middle;
              img{
                width:100%;
                height:auto;
              }
            }
            dl{
              margin-left:17px;
              display:inline-block;
              vertical-align:middle;
              font-size:14px;
              line-height:28px;
              font-weight:700;
              text-align:left;
            }
          }
        }
      }
      .recruitBox{
        border-top:1px solid #f0f0f0;
        margin-top:90px;
        padding-top:90px;
        position:relative;
        overflow:hidden;
        &:before{
          content:"";
          display:block;
          width:147px;
          height:204px;
          background:url(../img/lower_stripe02.png) no-repeat top left;
          background-size:147px auto;
          top:32px;
          right:0;
          position:absolute;
          z-index:10;
        }
        .topWrap{
          figure{
            height:456px;
            position: absolute;
            right:0;
            top:90px;
            margin-left: 400px;
            background: url(../img/contents/lower03_img01.jpg) no-repeat center center;
            background-size: cover;
            overflow: hidden;
            left : calc((100% - 1000px)/2) ;
            img{
              width: 100%;
              min-width: 740px;
              visibility: hidden;
            }
          }
          .clearfix{
            min-height:456px;
            h4{
              font-weight:700;
              font-size:40px;
              margin-bottom:27px;
              width:400px;
              line-height:1;
              letter-spacing:6px;
              span{
                font-family: 'Oswald', sans-serif;
                font-weight: 700;
                font-size:18px;
                display:block;
                margin-bottom: 15px;
                letter-spacing:1px;
              }
            }
            .red_block{
              position:relative;
              z-index:50;
              display:table;
              background-color:#e20c21;
              font-size:26px;
              color:#fff;
              font-weight:700;
              padding:10px 18px;
              letter-spacing: 3.5px;
              &+.red_block{
                margin-top:10px;
              }
            }
            p{
              margin-top:20px;
              width:345px;
              font-weight:700;
            }
          }
        }
        .bottomWrap{
          margin:56px auto 0;
          dl{
            margin:0 auto;
            width:760px;
            display:table;
            table-layout: fixed;
            border-bottom:1px solid #f0f0f0;
            padding:10px 0;
            &:first-of-type{
              border-top:1px solid #f0f0f0;
            }
            dt,dd{
              display:table-cell;
              vertical-align:top;
              font-size:16px;
              line-height:32px;
              padding:10px 0;
            }
            dt{
              font-weight:700;
              width:240px;
              border-left:3px solid #000;
              padding-left:28px;
            }
          }
          >div{
            border:1px solid #e20c21;
            width:100%;
            margin-top:60px;
            display:table;
            padding:14px 0;
            div{
              display:table-cell;
              vertical-align:middle;
              text-align:center;
              padding: 8px 0;
              &:first-of-type{
                width:600px;
                border-right: 1px solid #e20c21;
              }
              p{
                color:#e20c21;
                padding-left:30px;
                &.bold{
                  font-weight:700;
                }
                a{
                  color:#e20c21;
                  &:hover{
                    opacity:1;
                  }
                }
              }
            }
          }
        }
        &#ContBox03{
          &:before{
            top:400px;
            left:0;
            right:auto;
          }
          .topWrap{
            figure{
              right : calc((100% - 1000px)/2) ;
              left:0;
              margin-right: 400px;
              margin-left: 0;
              background: url(../img/contents/lower03_img02.jpg) no-repeat center center;
              background-size: cover;
            }
            .clearfix{
              h4{
                width:340px;
              }
              h4,p,.red_block{
                margin-left:auto;
              }
              .red_block{

              }
            }
          }
        }
        &#ContBox04{
          .topWrap{
            figure{
              background: url(../img/contents/lower03_img03.jpg) no-repeat center center;
              background-size: cover;
            }
          }
        }
      }
    }
  }

  /*
  お問い合わせ
  -------------------------------------*/
  #PageContact.pageIndex ,
  #PageContact.pageConfirm,
  #PageContact.pageThanks{
    #Container {
      .innerBasic {
        #Main {
          #ContBox01 {
            margin-top: 60px;
            h3 {

            }
            p {
              font-size: 16px;
              text-align: center;
              margin-bottom: 73px;
              span {
                display: block;
              }
              &:nth-of-type(2) {
                margin-bottom: 40px;
                margin-top: 54px;
                a {
                  &:hover {
                    opacity: .6;
                  }
                }
              }
            }
          }
          #ContBox02 {
            margin-top: 50px;
            margin-bottom: 90px;
            form {
              >dl {
                width: 760px;
                margin: 0 auto;
                border-top: 1px solid #f1f1f1;
                padding-bottom: 15px;
                font-size: 0;
                letter-spacing: normal;
                >dt {
                  width: 190px;
                  font-size: 16px;
                  font-weight: 700;
                  display: inline-block;
                  vertical-align: top;
                  padding-top: 34px;
                  padding-left: 10px;
                  letter-spacing: 1px;
                }
                >dd {
                  font-size: 16px;
                  vertical-align: top;
                  &:first-of-type {
                    width: 50px;
                    display: inline-block;
                    font-size: 14px;
                    font-weight: 700;
                    vertical-align: top;
                    text-align: center;
                    height: 30px;
                    line-height: 30px;
                    margin-top: 30px;
                    color: #ffffff;
                    background-color: #bebebe;
                    &.required {
                      color: #fefefe;
                      background-color: #e20c21;

                    }
                  }
                  &:nth-of-type(2) {
                    display: inline-block;
                    padding-top: 20px;
                    padding-bottom: 9px;
                    width: 520px;
                    >div#CautionText1 {
                      display: block;
                    }
                    >div.radio_box {
                      display: inline-block;
                      margin-left: 27px;
                      vertical-align: middle;
                      &:first-of-type {
                        margin-left: 0;
                      }
                      >div.radio {
                        margin-left: 1px;
                      }
                      label {
                        margin-left: 6px;
                        vertical-align: middle;
                      }
                    }
                    >input {
                      font-size: 16px;
                      width: 280px;
                      height: 46px;
                      background-color: #f0f0f0;
                      font-size: 16px;
                      color: #222;
                      padding-left: 17px;
                      letter-spacing: 1.5px;
                      &:focus{
                        border: 1px solid #191919;
                        background-color: #fff;
                      }
                    }
                    >label {
                      margin-left: 4px;
                    }
                    span {
                      width: 30px;
                      height: 30px;
                      background: transparent url(../img/img_radio02.png) no-repeat scroll left center;
                      background-size: 30px 30px;
                      input {
                        font-size: 16px;
                        width: 30px;
                        height: 30px;
                      }
                    }
                    span.checked {
                      background: transparent url(../img/img_radio01.png) no-repeat scroll left center;
                      background-size: 30px 30px;
                    }
                  }
                }
                &:first-of-type {  //お問い合わせ内容のラジオボタンの部分
                  dd {
                    &:nth-of-type(2) {
                      padding-top: 29px;
                      padding-bottom: 15px;
                      >div.radio {
                        margin-left: 30px;
                      }
                      >div {
                        &:nth-of-type(2) {
                          margin-left: 0;
                        }
                      }
                    }
                  }
                }
                &:nth-of-type(4) {  //郵便番号
                  dd {
                    &:nth-of-type(2) {
                      input {
                        width: 210px;
                      }
                    }
                  }
                }
                &:nth-of-type(5) {  //郵便番号
                  dd {
                    &:nth-of-type(2) {
                      input {
                        width: 510px;
                      }
                    }
                  }
                }
                &:nth-of-type(7) {  //メールアドレス
                  dd {
                    &:nth-of-type(2) {
                      padding-bottom: 0;
                      >dl {
                        dt {
                          >input {
                            font-size: 16px;
                            width: 380px;
                            height: 46px;
                            background-color: #f0f0f0;
                            font-size: 16px;
                            color: #222;
                            padding-left: 17px;
                            letter-spacing: 1.5px;
                            &:focus{
                              border: 1px solid #191919;
                              background-color: #fff;
                            }
                          }
                        }
                        dd {
                          letter-spacing: 1px;
                          &:first-of-type {
                            margin-top: 6px;
                            margin-bottom: 4px;
                          }
                          &:nth-of-type(2) {
                            >input {
                              font-size: 16px;
                              width: 380px;
                              height: 46px;
                              background-color: #f0f0f0;
                              font-size: 16px;
                              color: #222;
                              padding-left: 17px;
                              letter-spacing: 1.5px;
                              &:focus{
                                border: 1px solid #191919;
                                background-color: #fff;
                              }
                            }
                          }
                          &:nth-of-type(3) {
                            font-size: 14px;
                            line-height: 24px;
                            color: #888888;
                            margin-top: 15px;
                            letter-spacing: .6px;
                            span {
                              width: 100%;
                              height: 100%;
                              background: none;
                              display: block;

                            }
                          }
                        }
                      }
                    }
                  }
                }
                &:nth-of-type(8) {
                  dd {
                    &:nth-of-type(2) {
                      padding-top: 27px;
                      padding-bottom: 16px;
                      >div {
                        margin-left: 0px;
                        &:first-of-type {

                        }
                        &:nth-of-type(2) {
                          margin-left: 10px;
                        }
                        &:nth-of-type(3) {
                          margin-left: 29px;
                        }

                      }
                    }
                  }
                }
                &:nth-of-type(9) {
                  border-bottom: 1px solid #f1f1f1;
                  dd {
                    &:nth-of-type(2) {
                      width: 510px;
                      padding-bottom: 3px;
                      textarea {
                        padding-top: 13px;
                        padding-left: 16px;
                        width: 510px;
                        height: 185px;
                        background-color: #eee;
                        letter-spacing: 1.5px;
                        font-size: 16px;
                        color: #222;
                        resize: vertical;
                        &:focus{
                          border: 1px solid #191919;
                          background-color: #fff;
                        }
                      }
                    }
                  }
                }
              }
              ul.contact-form1 {
                margin-top: 60px;
                width: 340px;
                height: 70px;
                margin: 0 auto;
                li.confirm {
                  width: 100%;
                  height: 100%;
                  position: relative;
                  display: block;
                  background-color: #111;
                  opacity: 1;
                  text-decoration: none;
                  color: #fff;
                  -webkit-transition: .3s;
                  transition: .3s;
                  &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 0;
                    background: #fff;
                    -webkit-transform: scale(0, 1);
                    transform: scale(0, 1);
                  }
                  &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    display: block;
                    margin-top: -9px;
                    width: 18px;
                    height: 18px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url(../img/arrow_dubble_w.png);
                    background-size: 18px auto;
                  }
                  &:hover {
                    &:after {
                      -webkit-animation: hover-arrow-01_bw .6s forwards;
                      animation: hover-arrow-01_bw .6s forwards;
                    }
                  }
                  input {
                    position: relative;
                    z-index: 1;
                    display: block;
                    color: #fff;
                    width: 100%;
                    height: 100%;
                    font-weight: 700;
                    &:hover {
                      opacity: 1;
                      -webkit-animation: hover-01_b .6s forwards;
                      animation: hover-01_b .6s forwards;
                    }
                  }
                }
              }
            }
          }
          #ContBox03 {
            padding-top: 82px;
            padding-bottom: 17px;
            border-top: 1px solid #f1f1f1;
            h3 {
              width: 760px;
              margin: 0 auto;
              margin-bottom: 51px;
            }
            p {
              width: 760px;
              margin: 0 auto;
            }
            >div {
              width: 760px;
              margin: 0 auto;
              h4 {
                font-size: 16px;
                font-weight: 700;
                border-bottom: 1px solid #333;
                margin-top: 48px;
                margin-bottom: 23px;
                padding-bottom: 10px;
              }
              >p {
                font-size: 14px;
                line-height: 28px;
                letter-spacing: .6px;
                span {
                  display: block;
                }
              }
              >ul {
                font-size: 14px;
                li {
                  margin-top: 4px;
                  letter-spacing: .6px;
                  &:first-of-type {
                    margin-top: 3px;
                  }
                }
              }
//              &:nth-of-type(8) {
//                >ul {
//                  li {
//                    &:nth-of-type(3) {
//                      span {
//                        margin-left: 10px;
//                      }
//                    }
//                  }
//                }
//              }
            }
          }
        }
      }
    }
  }
  #PageContact.pageConfirm{
    #Container {
      .innerBasic {
        #Main {
          #ContBox00 {
          }
        }
      }
    }
  }

  /*
  お問い合わせ（確認画面）
  -------------------------------------*/
  #PageContact.pageConfirm {
    #Container {
      .innerBasic {
        #Main {
          #ContBox02 {
            margin-bottom: 57px;
            p {
              text-align: center;
              margin-top: 54px;
              margin-bottom: 50px;
              span {
                display: block;
              }
            }
            form {
              >dl {
                >dt {
                }
                >dd {
                  &:first-of-type {
                    &.required {
                    }
                  }
                  &:nth-of-type(2) {
                    padding-top: 33px;
                    padding-bottom: 18px;
                    letter-spacing: 1px;

                  }
                }
                &:first-of-type {  //お問い合わせ内容のラジオボタンの部分
                  dd {
                    &:nth-of-type(2) {
                      padding-top: 32px;
                      padding-bottom: 19px;

                    }
                  }
                }
                &:nth-of-type(7) {  //メールアドレス
                  dd {
                    &:nth-of-type(2) {
                      padding-bottom: 18px;
                    }
                  }
                }
                &:nth-of-type(8) {
                  dd {
                    &:nth-of-type(2) {
                      padding-top: 32px;
                      padding-bottom: 19px;
                    }
                  }
                }
                &:nth-of-type(9) {
                  dd {
                    &:nth-of-type(2) {
                      padding-bottom: 18px;
                    }
                  }
                }
              }
              ul.contact-form1 {
                li.confirm {
                  &:hover {
                    &:before {
                      -webkit-animation: hover-in-01 .3s forwards alternate, hover-out-01 .3s .3s forwards alternate;
                      animation: hover-in-01 .3s forwards alternate, hover-out-01 .3s .3s forwards alternate;
                    }
                  }
                }
                li.reset {
                  text-align: center;
                  margin-top: 25px;
                  input {
                    font-size: 14px;
                    color: #888888;
                    letter-spacing: 1px;
                    text-decoration: underline;
                    &:hover {
                      text-decoration: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  お問い合わせ（完了画面）
  -------------------------------------*/
  #PageContact.pageThanks {
    #Container {
      .innerBasic {
        #Main {
          #ContBox01 {
            margin-top: 60px;
            h3 {
              margin-bottom: 53px;
            }
            p {
              font-size: 16px;
              text-align: center;
              margin-bottom: 33px;
              span {
                display: block;
              }
              &:nth-of-type(2) {
                margin-top: 0;
                margin-bottom: 30px;
                a {
                  &:hover {
                    opacity: .6;
                  }
                }
              }
            }
            ul.aboutTopNav {
              li {
                width: 180px;
                height: 55px;
                margin: 0 auto;
                a {
                  width: 100%;
                  height: 100%;
                  text-decoration: none;
                  &:hover {
                    opacity: 1;
                  }
                  div {
                  display:block;
                  position:relative;
                  width:100%;
                  height:54px;
                  line-height:54px;
                  font-size:16px;
                  font-weight:700;
                  background-color: transparent;
                  border-bottom: 1px solid #000;
                  transition: .3s;
                  color:#333;
                  text-decoration:none;
                  padding-left:2px;
                  opacity:1;
                  &:before{
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 0;
                    content: '';
                    background: #000;
                    transform: scale(0, 1);
                  }
                  span {
                    position: relative;
                    z-index: 1;
                    display: block;
                  &:after{
                      content: "";
                      position: absolute;
                      display: block;
                      top: 50%;
                      right: 0;
                      margin-top: -8px;
                      width: 9px;
                      height: 13px;
                      background-repeat: no-repeat;
                      background-position: center center;
                      background-image: url(../img/img_arrow01.png);
                      background-size: 9px auto;
                  }
                  }
                }
                }
                a {
                  text-decoration: none;
                  &:hover{
                    opacity: 1;
                    div {
                animation: hover-03 .6s forwards;
                opacity:1;
                &:before {
                              animation: hover-in-01 .3s forwards alternate, hover-out-01 .3s .3s forwards alternate;
                }
                span {
                  &:after {
                     animation: hover-arrow-02_b .6s forwards;
                  }
                }                   }
                  }
                }
              }
            }
          }
        }
      }
    }
  }


}

@media screen and (min-width: 768px) and (-ms-high-contrast: active),
print and (-ms-high-contrast: none),
screen and (min-width: 768px) and (-ms-high-contrast: none) {
  #TopicPath {
    ol {
      li {
        background-position:  left 2px;
      }
    }
  }

}

@media print,
screen and (max-width: 767px) {
  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
    #Container{
      padding:0;
      background:none;
    }
    #MainImgTop{
      height:405px;
      overflow:hidden;
      position:relative;
      #MainImgInner{
        .bxslider{
          li{
            height:405px;
            width:100%;
            //min-width:1280px;
            background-image:url(../img/contents/top_slide_visuA.jpg);
            background-repeat:no-repeat;
            background-position:center center;
            background-size:cover;
            img{
              visibility:hidden;
              width:100%;
              height:auto;
              //min-width:1280px;
              //position:absolute;
              //top: 50%;
              //left: 50%;
              //transform: translateY(-50%) translateX(-50%);
            }
            &:nth-child(2){
              background-image:url(../img/contents/top_slide_visuB.jpg);
            }
            &:nth-child(3){
              background-image:url(../img/contents/top_slide_visuC.jpg);
            }
            &:nth-child(4){
              background-image:url(../img/contents/top_slide_visuD.jpg);
            }
            &:nth-child(5){
              background-image:url(../img/contents/top_slide_visuE.jpg);
            }
          }
        }
        .mainTxt{
          position:absolute;
          bottom: 45px;
          left: 15px;
          z-index:100;
          width:100%;
          .innerBasic{
            position:relative;
            &:before{
              width:266px;
              height:263px;
              content:"";
              display:block;
              position:absolute;
              bottom: -45px;
              left:-15px;
              background:url(../img/top_mainbg.png) no-repeat top left;
              background-size:266px auto;
              z-index:100;
            }
          }
          .mainTxtInner{
            position:relative;
            z-index:200;
          }
          h2{
            font-family: 'Oswald', sans-serif;
            font-weight: 700;
            font-size:45px;
            line-height:51px;
            color:#fff;
            text-align:left;
            span{
              color:#ed1b2f;
            }
          }
          p{
            font-size:14px;
            font-weight:700;
            letter-spacing: 3px;
            color:#fff;
          }
          ul{
            li{
              a{
                width:200px;
                height:60px;
                line-height:60px;
                text-align:left;
                font-size:20px;
                font-family: 'Oswald', sans-serif;
                font-weight: 400;
                background-color: transparent;
                color:#fff;
                border-bottom: 1px solid #fff;
                &:before{
                  background: #fff;
                }
                span{
                  &:after{
                    content: "";
                    position: absolute;
                    display: block;
                    top: 50%;
                    right: 0;
                    margin-top: -9px;
                    width: 23px;
                    height: 18px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url(../img/arrow_dubble_w.png);
                    background-size: 23px auto;
                  }
                }
              }
            }
          }
        }
      }
    }
    #Main{
      margin:0;
      border-top:5px solid #ed1b2f;
      #ContBox01{
        .big_Box{
          ul{
            li{
              figure{
                background-image:url(../img/contents/top_cont_company.jpg);
                background-repeat:no-repeat;
                background-position:center center;
                background-size:cover;
                height:115px;
                img{
                  display:none;
                }
              }
              >div{
                background-image:url(../img/cont_stripe_r.png);
                background-repeat:no-repeat;
                background-position:left bottom;
                background-size:95px auto;
                padding:60px 0;
                h3{
                  font-size:14px;
                  font-weight:700;
                  line-height:1;
                  letter-spacing: 1px;
                  &:after{
                    display:none;
                  }
                  span{
                    display: block;
                    margin-bottom: 10px;
                    font-family: "Oswald", sans-serif;
                    font-size: 40px;
                    letter-spacing: 1px;
                    font-weight: 700;
                  }
                }
                p{
                  text-align:center;
                }
                a{
                  margin:35px auto 0;
                  height:50px;
                  line-height:50px;
                  width:110px;
                  font-family: "Oswald", sans-serif;
                  font-size: 16px;
                  font-weight: 400;
                  padding-left:12px;
                  background-color:transparent;
                  background-position: center right 14px;
                }
              }
              &+li{
                figure{
                  background-image:url(../img/contents/top_cont_service.jpg);
                }
                >div{
                  background-color:#111111;
                  background-image:url(../img/cont_stripe_b.png);
                  background-position:right bottom;
                  background-size:70px auto;
                  h3{
                    color:#ccc;
                    span{
                      color:#fff;
                    }
                  }
                  p{
                    color:#ccc;
                  }
                  a{
                    border-bottom: 1px solid #fff;
                    color:#fff;
                    background-image: url(../img/img_arrow02.png);
                  }
                }
              }
            }
          }
        }
      }
      #ContBox02{
        background-color:#f0f0f0;
        padding:55px 0 80px;
        >div{
          position:relative;
          figure{
            img{
              width:100%;
              height:auto;
            }
          }
          .innerBasic{
            position: absolute;
            bottom: -40px;
            left: 15px;
            margin: auto;
          }
          h4{
            font-family: "Oswald", sans-serif;
            font-size: 40px;
            font-weight: 700;
            color:#fff;
            line-height:36px;
          }
          a{
            padding-left:15px;
            width:235px;
            height:70px;
            line-height:70px;
            font-size:20px;
            background-position: center right 12px;
            span{
            }
          }
        }
      }
    }
  }

  /*
  企業情報
  -------------------------------------*/
  #PageAbout.pageIndex {
    #Main{
      padding:0 15px;
      margin-bottom:85px;
      p{
        &+p{
          margin-top:32px;
        }
      }
      .aboutTopNav{
        margin-top:58px;
        li{
          &+li{
            margin-top:30px;
          }
          figure{
            display:table-cell;
            vertical-align:middle;
            width:165px;
            padding-right:15px;
            overflow:hidden;
            img{
              width:150px;
              height:auto;
              transition: all .5s ease-in-out 0s;
            }
          }
          div{
            display:table-cell;
            vertical-align: middle;
            position:relative;
            width:100%;
            font-size:16px;
            font-weight:700;
            background-color: transparent;
            border-bottom: 1px solid #000;
            color:#333;
            text-decoration:none;
            padding-left:2px;
            background-repeat: no-repeat;
            background-position: center right;
            background-image: url(../img/img_arrow01.png);
            background-size: 9px auto;
          }
          a{
            text-decoration:none;
            display:table;
            width:100%;
            table-layout: fixed;
          }
        }
      }
    }
  }

  /*
  社長挨拶
  -------------------------------------*/
  #PageAbout.pageMessageIndex {
    #Container {
      .innerBasic {
        #Main {
          #ContBox01 {
            padding: 0 15px 74px;
            p {
              width: 100%;
              margin-bottom: 34px;
              margin: 0 auto;
              letter-spacing: .8px;
              span {
                display: block;
              }
              &:nth-of-type(3) {
                margin-bottom: 40px;
              }
              &:nth-of-type(4) {
                line-height: 36px;
              }
            }
            .big_p {
              font-size: 20px;
              font-weight: 700;
            }
            .right_p {
              text-align: right;
            }
            ul {
              font-size: 0;
              letter-spacing: normal;
              margin-top: -14px;
              li {
                width: 100%;
                height: 75px;
                font-size: 16px;
                font-weight: 700;
                display: inline-block;
                margin-bottom: 17px;
                a {
                  width: 100%;
                  height: 100%;
                  line-height: 75px;
                }
                &:first-of-type {
                  border-bottom: 1px solid #ed1b2f;
                  span {
                    color: #ed1b2f;
                    font-size: 16px;
                    line-height: 75px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  企業理念
  -------------------------------------*/
  #PageAbout.pageVisionIndex {
    #Container {
      .innerBasic {
        padding: 0 15px;
        #Main {
          #ContBox01 {
            h3 {
              margin-bottom: 54px;
            }
            p {
              text-align: left;
              margin-bottom: 30px;
              letter-spacing: .6px;
              &:first-of-type {
                margin-top: -14px;
              }
            }
            >div {
              text-align: center;
              margin-top: -6px;
              img {
                width: 110px;
                display: inline-block;
                vertical-align: top;
              }
              dl {
                display: inline-block;
                vertical-align: top;
                text-align: left;
                font-weight: 700;
                font-size: 14px;
                line-height: 28px;
                margin-top: 21px;
                dt {
                  padding-left: 15px;
                }
                dd {
                  padding-left: 15px;
                }
              }
            }
          }
          #ContBox02 {
            margin-top: 59px;
            margin-bottom: 68px;
            h3 {
              margin-bottom: 49px;
            }
            p {
              text-align: center;
              font-size: 22px;
              line-height: 40px;
              letter-spacing: 3px;
              font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
              font-weight: 400;
              span {
                display: block;
              }
              &:first-of-type {
                margin-top: -13px;
              }
            }
            ul.aboutTopNav {
              margin-top: 36px;
              font-size: 0;
              letter-spacing: normal;
              li {
                width: 100%;
                height: 75px;
                font-weight: 700;
                font-size: 16px;
                display: inline-block;
                margin-bottom: 17px;
                a {
                  width: 100%;
                  height: 100%;
                  line-height: 75px;
                  span {

                  }
                }
                &:first-of-type {
                  margin-left: 0;
                }
                &:nth-of-type(2) {
                  >div {
                    line-height: 75px;
                    border-bottom: 1px solid #ed1b2f;
                    span {
                      color: #ed1b2f;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  会社概要
  -------------------------------------*/
  #PageAbout.pageCompanyIndex {
    #Container {
      .innerBasic {
        #Main {
          #ContBox01 {
            >div.box01 {
              width: 100%;
              &:nth-of-type(4) {
                dd {
                  a {

                  }
                }
              }
              &:first-of-type {
                margin-top: 46px;
              }
              dl {
                width: 100%;
                padding: 25px 15px 0;
                margin: 0 auto;
                border-bottom: 1px solid #f0f0f0;
                &:first-of-type {
                  border-top: 1px solid #f0f0f0;
                }
                dt {
                  border-left: 3px solid #333;
                  font-size: 16px;
                  font-weight: 700;
                  width: 100%;
                  vertical-align: top;
                  padding-top: 3px;
                  padding-bottom: 3px;
                  padding-left: 12px;
                  margin-right: 7px;
                  letter-spacing: 1px;
                }
                dd {
                  font-size: 16px;
                  width: 100%;
                  padding-top: 13px;
                  padding-bottom: 23px;
                  letter-spacing: 1px;
                  ul {
                    li {
                      margin-bottom: 8px;
                      &:last-of-type {
                        margin-bottom: 0;
                      }
                    }
                  }
                  #Gmap01,
                  #Gmap02{
                    height:200px;
                    margin:10px 0;
                  }
                  ul.txtLink {
                    li {
                      margin-bottom: 30px;
                      text-align:right;
                      font-size:14px;
                      a{
                        color:#888;
                      }
                    }
                  }
                  ul.txtLink02 {
                    li {
                      margin-bottom: 0;
                      text-align:right;
                      font-size:14px;
                    }
                  }
                }
              }
            }
          }

          #ContBox02 {
            margin-top: 47px;
            margin-bottom: 63px;
            dl {
              width: 100%;
              padding: 0 15px;
              margin: 0 auto;
              font-size: 0;
              letter-spacing: normal;
              padding-top: 13px;
              padding-bottom: 11px;
              &:first-of-type {
                margin-top: -6px;
              }
              &:after {
                content: ".";
                display: block;
                height: 0;
                font-size:0;
                clear: both;
                visibility:hidden;
              }
              dt {
                width: 100%;
                font-size: 16px;
                font-weight: 700;
                vertical-align: top;
                margin-right: 35px;
                letter-spacing: 1px;
              }
              dd {
                font-size: 16px;
                display: inline-block;
                vertical-align: top;
                letter-spacing: .7px;
                &:first-of-type {
                  width: 50px;
                  height: 24px;
                  position: relative;
                  float: left;
                  margin-top: 8px;
                  &:after {
                    content: "";
                    position: absolute;
                    bottom: 12px;
                    left: 0;
                    right:0;
                    margin: auto;
                    display: block;
                    width: 50px;
                    height: 1px;
                    background: #333;
                  }
                }
                &:nth-of-type(2) {
                  display: inline-block;
                  float: left;
                  width: 100%;
                  margin-left: -50px;
                  padding-left: 60px;
                  margin-top: 4px;
                  line-height: 31px;
                }
              }
            }
            ul.aboutTopNav {
              padding: 0 15px;
              margin-top: 36px;
              font-size: 0;
              letter-spacing: normal;
              li {
                width: 100%;
                height: 75px;
                font-weight: 700;
                font-size: 16px;
                display: inline-block;
                margin-bottom: 17px;
                a {
                  width: 100%;
                  height: 100%;
                  line-height: 75px;
                  span {
                  }
                }
                &:first-of-type {
                  margin-left: 0;
                }
                &:nth-of-type(3) {
                  >div {
                    line-height: 75px;
                    border-bottom: 1px solid #ed1b2f;
                    span {
                      color: #ed1b2f;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  事業案内
  -------------------------------------*/
  #PageService.pageIndex {
    #Container {
      padding-bottom: 83px;
      #Main {
        .contBox {  //ブロック共通設定
          h4 {
            text-align: center;
            font-size: 28px;
            font-weight: 700;
            letter-spacing: 4px;
            margin-top: 55px;
            margin-bottom: 35px;
            &:after {
              content: "";
              display: block;
              margin: 20px auto 0;
              width: 70px;
              height: 4px;
              background-color: #000;
            }
          }
          .click_sp.open {
            h3 {
              &:after {
                background: url(../img/img_mainasu.jpg) no-repeat;
                background-size: 30px 4px;
                top: -29px;
                right: 15px;
              }
            }
          }
          .click_sp {  //大きい画像のブロック
            // position: relative;
            text-align: center;
            width: 100%;
            margin: 0 auto;
            padding-top: 33px;
            padding-bottom: 35px;
            img {
              width: 50px;
              margin-right: 48px;
              // left: 0;
              // right: 0;
              // top: -76px;
              // margin: auto;
              // position: absolute;
            }
            h3 {
              font-size: 30px;
              letter-spacing: 4px;
              margin-bottom: 0;
              padding-top: 6px;
              // position: relative;
              &:after {
                background-color: transparent;
                content: "";
                display: inline-block;
                width: 30px;
                height: 30px;
                background: url(../img/img_plus.png) no-repeat;
                background-position: left top;
                background-size: 30px 30px;
                position: absolute;
                top: -42px;
                right: 15px;
              }
            }
          }

          .showBox_sp {
            width: 100%;
            margin: 0 auto;
            display: none;  //初期非表示
            >div {
              &:first-of-type {
                position: relative;
                img {
                  width: 100%;
                }
                p {
                  font-size: 40px;
                  font-weight: 700;
                  font-family: 'Oswald', sans-serif;
                  text-transform: uppercase;
                  letter-spacing: 0px;
                  color: #ffffff;
                  position: absolute;
                  bottom: 2px;
                  left: 13px;
                  opacity: .9;
                  span {
                    margin-left: 6px;
                  }
                }
              }
              &:nth-of-type(2),&:nth-of-type(3) {
                padding: 0 15px;
              }
            }
            figure {
              text-align: center;
              img {
                width: 100%;
                margin-top: 30px;
                margin-bottom: 18px;
              }
            }
            .aboutTopNav { //右移動リンクボタン設定
              text-align: center;
              width: 100%;
              margin: 0 auto;
              li {
                width: 100%;
                height: 70px;
                font-size: 16px;
                font-weight: 700;
                display: inline-block;
                border-bottom: 1px solid #000000;
                .link_leftR02_r {
                  color: #333333;
                  background-color: transparent;
                  line-height: 70px;
                  &:before {
                    background: #cfcfcf;
                  }
                  span {
                    padding-right: 37px;
                    text-align: right;
                    position: relative;
                    width: 100%;
                    display: inline-block;
                    img {
                      position: absolute;
                      left: 0;
                      top: 17px;
                      vertical-align: baseline;
                    }
                    &:after {
                      content: "";
                      position: absolute;
                      top: 50%;
                      right: 0;
                      display: block;
                      margin-top: -9px;
                      width: 23px;
                      height: 18px;
                      background-repeat: no-repeat;
                      background-position: center center;
                      background-image: url(../img/arrow_dubble_b.png);
                      background-size: 23px auto;
                    }
                  }
                  @keyframes hover-arrow-01_r {
                    0%, 100% {
                      background-image: url(../img/arrow_dubble_w.png);
                    }
                    50% {
                      background-image: url(../img/arrow_dubble_w.png);
                    }
                  }
                }
              }
            }
          }
        }
        #ContBox01 {
          padding-bottom: 51px;
          border-bottom: 1px solid #f0f0f0;
          p {
            width: 100%;
            padding: 0 15px;
            font-size: 16px;
            text-align: left;
          }
          >div.disp_pc {
            width: 100%;
            margin: 0 auto;
            .aboutTopNav{
              margin-top:80px;
              li{
                width: 300px;
                float:left;
                &+li{
                  margin-left:50px;
                }
                figure{
                  margin-bottom:17px;
                  width:100%;
                  overflow:hidden;
                  img{
                    width:100%;
                    height:auto;
                    transition: all .5s ease-in-out 0s;
                  }
                }
                div{
                  display:block;
                  position:relative;
                  width:100%;
                  height:54px;
                  line-height:54px;
                  font-size:16px;
                  font-weight:700;
                  background-color: transparent;
                  border-bottom: 1px solid #000;
                  transition: .3s;
                  color:#333;
                  text-decoration:none;
                  padding-left:2px;
                  opacity:1;
                  &:before{
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 0;
                    content: '';
                    background: #000;
                    transform: scale(0, 1);
                  }
                  span{
                    position: relative;
                    z-index: 1;
                    display: block;
                    &:after{
                      content: "";
                      position: absolute;
                      display: block;
                      top: 50%;
                      right: 0;
                      margin-top: -4px;
                      width: 9px;
                      height: 13px;
                      background-repeat: no-repeat;
                      background-position: center center;
                      background-image: url(../img/arrow_shita_b.png);
                      background-size: 9px auto;}
                      &:hover {
                        background-image: url(../img/arrow_shita_w.png);
                    }
                  }
                }
                a{
                  text-decoration:none;
                  &:hover{
                    figure{
                      img{
                        transform: scale(1.10);
                      }
                    }
                    div{
                      animation: hover .6s forwards;
                      opacity:1;
                      &:before{
                        animation: hover-in .3s forwards alternate, hover-out .3s .3s forwards alternate;
                      }
                      span{
                        &:after{
                          animation: hover-arrow-02_b .6s forwards;
                        }
                      }
                      @keyframes hover {
                        0%, 100% {
                          color: #333;
                        }
                        50% {
                          color: #fff;
                        }
                      }
                      @keyframes hover-in {
                        0% {
                          transform-origin: left top;
                          transform: scale(1, 0);
                        }
                        100% {
                          transform-origin: left top;
                          transform: scale(1, 1);
                        }
                      }
                      @keyframes hover-out {
                        0% {
                          transform-origin: left bottom;
                          transform: scale(1, 1);
                        }
                        100% {
                          transform-origin: left bottom;
                          transform: scale(1, 0);
                        }
                      }
                      // @keyframes hover {
                      //   0%, 100% {
                      //     background-image: url(../img/arrow_shita_b.png);
                      //     background-repeat: no-repeat;
                      //   }
                      //   50% {
                      //     background-image: url(../img/arrow_shita_w.png);
                      //     background-repeat: no-repeat;
                      //   }
                      // }
                    }
                  }
                }
                &:nth-of-type(4),&:nth-of-type(5) {
                  margin-top: 50px;
                }
                &:nth-of-type(4) {
                  margin-left: 175px;
                }
              }
            }
          }
        }
        #ContBox02 {
          // margin-top: 107px;
          border-bottom: 1px solid #f0f0f0;
          // padding-bottom: 37px;
          .click_sp {
            img {
              left: -48px;
            }
            h3 {

            }
          }
          .showBox_sp {
            >div {
              &:first-of-type {  //大きい画像ブロック
                img {

                }
                p {
                  span {

                  }
                }
              }
              &:nth-of-type(2) {  //中間文字ブロック
                ul {
                  width: 100%;
                  margin: 0 auto;
                  margin-top: 37px;
                  li {
                    font-size: 16px;
                    line-height: 26px;
                    font-weight: 700;
                    margin-bottom: 8px;
                    margin-left: 26px;
                    position: relative;
                    span {
                      font-size: 20px;
                      font-family: 'Oswald', sans-serif;
                      font-weight: 400;
                      color: #e20c21;
                      margin-right: 11px;
                      position: absolute;
                      top: -3px;
                      left: -26px;
                    }
                  }
                }
              }
              &:nth-of-type(3) {  //下段ブロック
                dl {
                  font-size: 0;
                  letter-spacing: normal;
                  text-align: center;
                  width: 100%;
                  margin: 0 auto 10px;
                  dt {
                    text-align: left;
                    font-size: 16px;
                    width: 100%;
                    vertical-align: top;
                    margin-top: 5px;
                    font-weight: 700;
                    padding-top: 3px;
                    letter-spacing: 1px;
                    border-bottom: 1px solid #7f7f7f;
                    padding-bottom: 9px;
                  }
                  dd {
                    font-size: 16px;
                    line-height: 32px;
                    width: 100%;
                    vertical-align: top;
                    margin-top: 5px;
                    text-align: left;
                    padding-top: 3px;
                    padding-bottom: 3px;
                    letter-spacing: .6px;
                    span {
                      display: block;
                    }
                  }
                  &:nth-of-type(4) {
                    margin-bottom: 47px;
                  }
                }
              }
            }
            figure {
              img {

              }
            }
          }
        }
        #ContBox03 {
          border-bottom: 1px solid #f0f0f0;
          .click_sp {
            img {
              left: -48px;
            }
          }
          .showBox_sp {
            margin-bottom: 60px;
            >div {
              &:first-of-type {

              }
              &:nth-of-type(2) {
                p {
                  text-align: left;
                  line-height: 32px;
                  letter-spacing: .8px;
                  &:first-of-type {
                    margin-top: 37px;
                  }
                  &:nth-of-type(2) {
                    margin-top: 32px;
                  }
                  &:nth-of-type(3) {
                    margin-top: -2px;
                    margin-bottom: 12px;
                  }
                }
                >ul {
                  text-align: left;
                  margin-top: 2px;
                  li {
                    color: #e20c21;
                    font-size: 24px;
                    font-weight: 700;
                    margin-bottom: 5px;
                    span {
                      color: #333333;
                      padding-left: 10px;
                    }
                  }
                }
              }
              &:nth-of-type(3) {
                margin-top: 3px;
                ul.aboutTopNav {
                  li {
                    border-bottom: 1px solid #000000;
                    text-align: right;
                    a {
                      span {
                        position: relative;
                        text-align: right;
                        img {
                          position: absolute;
                          left: 0;
                          vertical-align: baseline;
                        }
                      }
                    }
                    &:first-of-type {
                      margin-bottom: 22px;
                      a {
                        span {
                          img {
                            width: 140px;
                          }
                        }
                      }
                    }
                    &:nth-of-type(2) {
                      a {
                        span {
                          img {
                            width: 80px;
                          }
                        }
                      }
                    }
                  }

                }
              }
            }
            figure {
              img {
                margin-top: 22px;
              }
            }
          }
        }
        #ContBox04 {
          border-bottom: 1px solid #f0f0f0;
          .click_sp {
            img {
            }
          }
          .showBox_sp {
            margin-bottom: 62px;
            >div {
              &:nth-of-type(2) {
                >div {
                  text-align: center;
                  >ul {
                    text-align: left;
                    li {
                    font-size: 16px;
                    line-height: 26px;
                    font-weight: 700;
                    margin-bottom: 8px;
                    margin-left: 26px;
                    position: relative;
                      span {
                      font-size: 20px;
                      font-family: 'Oswald', sans-serif;
                      font-weight: 400;
                      color: #e20c21;
                      margin-right: 11px;
                      position: absolute;
                      top: -3px;
                      left: -26px;
                      }
                    }
                    &:first-of-type {
                      display: inline-block;
                      vertical-align: top;
                      width: 100%;
                    }
                    &:nth-of-type(2) {
                      display: inline-block;
                      vertical-align: top;
                      width: 100%;
                    }
                  }
                }
              }
              &:nth-of-type(3) {
                margin-top: 2px;
                ul.aboutTopNav {
                  li {
                    border-bottom: 1px solid #000000;
                    a {
                      span {
                        img {
                          width: 120px;
                          top: 15px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        #ContBox05 {
          border-bottom: 1px solid #f0f0f0;
          .click_sp {
            img {
              top: -75px;
              left: -48px;
            }
          }
          .showBox_sp {
            margin-bottom: 61px;
            >div {
              &:nth-of-type(2) {
                ul {
                  width: 100%;
                  margin: 0 auto;
                  margin-top: -1px;
                  li {
                    font-size: 16px;
                    line-height: 26px;
                    font-weight: 700;
                    margin-bottom: 8px;
                    margin-left: 26px;
                    position: relative;
                    span {
                      font-size: 20px;
                      font-family: 'Oswald', sans-serif;
                      font-weight: 400;
                      color: #e20c21;
                      margin-right: 11px;
                      position: absolute;
                      top: -3px;
                      left: -26px;
                    }
                  }
                }
              }
              &:nth-of-type(3) {
                margin-top: -2px;
                ul.aboutTopNav {
                  li {
                    a {
                      span {
                        img {
                          width: 130px;
                          top: 14px;
                        }
                      }
                    }
                  }
                }
              }
            }
            figure {
              p {
                width: 100%;
                padding: 0 15px;
                margin-bottom: 13px;
                margin-top: 5px;
                letter-spacing: .8px;
              }
            }
          }
        }
        #ContBox06 {
          border-bottom: 1px solid #f0f0f0;
          .click_sp {
            img {
              left: -47px;
            }
          }
          .showBox_sp {
            >div {
              &:nth-of-type(2) {
                p {
                  text-align: left;
                  letter-spacing: .7px;
                  &:first-of-type {
                    margin-top: 41px;
                  }
                  &:nth-of-type(2) {
                    margin-top: 32px;
                  }
                }
              }
              &:nth-of-type(3) {
                dl {
                  width: 100%;
                  margin: 0 auto 11px;
                  dt {
                    text-align: left;
                    font-size: 16px;
                    width: 100%;
                    vertical-align: top;
                    margin-top: 5px;
                    font-weight: 700;
                    padding-top: 3px;
                    padding-bottom: 8px;
                    letter-spacing: 1px;
                    border-bottom: 1px solid #7f7f7f;
                  }
                  dd {
                    font-size: 16px;
                    line-height: 32px;
                    width: 100%;
                    vertical-align: top;
                    margin-top: 5px;
                    text-align: left;
                    padding-top: 3px;
                    padding-bottom: 3px;
                    letter-spacing: .8px;
                    span {
                      display: block;
                      line-height: 31px;
                      margin-top: 4px;
                    }
                  }
                }
                ul {
                  &:first-of-type {
                    font-size: 0;
                    letter-spacing: normal;
                    text-align: center;
                    margin-top: 18px;
                    font-size: 0;
                    letter-spacing: normal;
                    li {
                      width: 50%;
                      display: inline-block;
                      height: 60px;
                      border: 1px solid #888888;
                      display: inline-block;
                      font-size: 16px;
                      background-color:#fff;
                      background-position: center center;
                      background-repeat:no-repeat;
                      &:hover {
                        opacity: .6;
                      }
                      a {
                        width: 100%;
                        height: 100%;
                        line-height: 80px;
                        display: block;
                      }
                      &:first-of-type {
                        background-image: url(../img/img_sonpo.jpg);
                        background-size: 145px auto;
                      }
                      &:nth-of-type(2) {
                        background-image: url(../img/img_kaijou.jpg);
                        background-size: 115px auto;
                        border-left: none;
                      }
                    }
                  }
                }
                ul.aboutTopNav {
                  margin-top: 20px;
                  li {
                    a {
                      span {
                        img {
                          width: 165px;
                          top: 8px;
                        }
                      }
                    }
                  }
                }
              }
            }
            figure {
              img {
                margin-top: 35px;
              }
            }
          }
        }
      }
    }
  }

  /*
  採用情報
  -------------------------------------*/
  #PageRecruit.pageIndex {
    #Main{
      margin-bottom: 82px;
      .aboutTopNav{
        margin-bottom:50px;
        li{
          &+li{
            margin-top:43px;
          }
          a{
            font-weight:700;
            font-size:16px;
            background-size: 13px auto;
            padding-bottom:25px;
            background-position: top 8px right;
          }
        }
      }
      #ContBox01{
        p{
          &+p{
            margin-top:32px;
          }
        }
        .innerBasic{
          padding:0 15px;
          >div{
            margin-top:20px;
            text-align:center;
            figure{
              width:110px;
              height:110px;
              display:inline-block;
              vertical-align:middle;
              img{
                width:100%;
                height:auto;
              }
            }
            dl{
              margin-left:17px;
              display:inline-block;
              vertical-align:middle;
              font-size:14px;
              line-height:28px;
              font-weight:700;
              text-align:left;
            }
          }
        }
      }
      .recruitBox{
        //border-top:1px solid #f0f0f0;
        margin-top:90px;
        position:relative;
        &:before{
          content:"";
          display:block;
          width:73px;
          height:102px;
          background:url(../img/lower_stripe02.png) no-repeat top left;
          background-size:73px auto;
          top:-30px;
          right:0;
          position:absolute;
          z-index:10;
        }
        .topWrap{
          figure{
            height:232px;
            background: url(../img/contents/lower03_img01.jpg) no-repeat center center;
            background-size: cover;
            overflow: hidden;
            img{
              display:none;
            }
          }
          .clearfix{
            margin-top: 44px;
            h4{
              font-weight:700;
              font-size:30px;
              margin-bottom:27px;
              padding:0 15px;
              line-height:1;
              letter-spacing:6px;
              span{
                font-family: 'Oswald', sans-serif;
                font-weight: 700;
                font-size:14px;
                display:block;
                margin-bottom: 10px;
                letter-spacing:1px;
              }
            }
            .red_block{
              position:relative;
              z-index:50;
              display:table;
              background-color:#e20c21;
              font-size:16px;
              color:#fff;
              font-weight:700;
              padding:8px 15px;
              letter-spacing: 2px;
              &+.red_block{
                margin-top:5px;
              }
            }
            p{
              margin-top:20px;
              padding:0 15px;
              font-weight:700;
            }
          }
        }
        .bottomWrap{
          margin:40px auto 0;
          dl{
            display:table;
            width:100%;
            table-layout: fixed;
            border-bottom:1px solid #f0f0f0;
            padding:25px 15px;
            &:first-of-type{
              border-top:1px solid #f0f0f0;
            }
            dt,dd{
              font-size:16px;
              line-height:32px;
            }
            dt{
              font-weight:700;
              border-left:3px solid #000;
              padding-left:12px;
              margin-bottom: 7px;
            }
          }
          >div{
            border:1px solid #e20c21;
            margin:45px 15px 0;
            padding:0 15px;
            div{
              padding: 20px 15px;
              &:first-of-type{
                border-bottom: 1px solid #e20c21;
              }
              p{
                color:#e20c21;
                &.bold{
                  font-weight:700;
                }
                a{
                  color:#e20c21;
                }
              }
            }
          }
        }
        &#ContBox03{
          .topWrap{
            figure{
              background: url(../img/contents/lower03_img02.jpg) no-repeat center center;
              background-size: cover;
            }
          }
        }
        &#ContBox04{
          .topWrap{
            figure{
              background: url(../img/contents/lower03_img03.jpg) no-repeat center center;
              background-size: cover;
            }
          }
        }
      }
    }
  }

  /*
  お問い合わせ
  -------------------------------------*/
  #PageContact.pageIndex ,
  #PageContact.pageConfirm,
  #PageContact.pageThanks{
    #Container {
      .innerBasic {
        #Main {
          #ContBox01 {
            margin-top: 55px;
            padding: 0 15px;
            h3 {

            }
            p {
              font-size: 16px;
              text-align: justify;
              margin-bottom: 43px;
              letter-spacing: .7px;
              span {
              }
              &:nth-of-type(2) {
                margin-bottom: 35px;
                margin-top: 39px;
                a {
                  &:hover {
                    opacity: .6;
                  }
                }
              }
            }
          }
          #ContBox02 {
            margin-bottom: 75px;
            form {
              >dl {
                padding: 0 15px;
                width: 100%;
                margin: 0 auto;
                border-top: 1px solid #f1f1f1;
                padding-bottom: 15px;
                font-size: 0;
                letter-spacing: normal;
                >dt {
                  font-size: 16px;
                  font-weight: 700;
                  display: inline-block;
                  vertical-align: top;
                  padding-top: 28px;
                  padding-left: 10px;
                  letter-spacing: 1px;
                }
                >dd {
                  font-size: 16px;
                  vertical-align: top;
                  &:first-of-type {
                    width: 50px;
                    display: inline-block;
                    font-size: 14px;
                    font-weight: 700;
                    vertical-align: top;
                    text-align: center;
                    height: 30px;
                    line-height: 30px;
                    margin-top: 25px;
                    color: #ffffff;
                    background-color: #bebebe;
                    &.required {
                      color: #fefefe;
                      background-color: #e20c21;
                    }
                  }
                  &:nth-of-type(2) {
                    padding-top: 16px;
                    padding-bottom: 9px;
                    width: 100%;
                    >div#CautionText1 {
                      display: block;
                    }
                    >div.radio_box {
                      vertical-align: middle;
                      margin-top: 0px;
                      margin-left: 0px;
                    }
                    >input {
                      font-size: 16px;
                      width: 100%;
                      height: 46px;
                      background-color: #f0f0f0;
                      font-size: 16px;
                      color: #222;
                      padding-left: 17px;
                      letter-spacing: 1.5px;
                      -webkit-appearance: none;
                      border-radius: 0;
                      &:focus{
                        border: 1px solid #191919;
                        background-color: #fff;
                      }
                    }
                    >label {
                      margin-left: 4px;
                      margin-right: 10px;
                    }
                    span {
                      width: 30px;
                      height: 30px;
                      background: transparent url(../img/img_radio02.png) no-repeat scroll left center;
                      background-size: 30px 30px;
                      input {
                        font-size: 16px;
                        width: 30px;
                        height: 30px;
                      }
                    }
                    span.checked {
                      background: transparent url(../img/img_radio01.png) no-repeat scroll left center;
                      background-size: 30px 30px;
                    }
                  }
                }
                &:first-of-type {  //お問い合わせ内容のラジオボタンの部分
                  dd {
                    &:nth-of-type(2) {
                      padding-top: 23px;
                      padding-bottom: 10px;
                      >div.radio {
                        margin-left: 30px;
                      }
                      >div {
                        label {
                          margin-left: 5px;
                          letter-spacing: 1px;
                          vertical-align: middle;
                        }
                        &:nth-of-type(2) {
                          margin-left: 0;
                          margin-top: 0;
                        }
                        &:nth-of-type(3),&:nth-of-type(4) {
                          margin-top: 8px;
                        }
                      }
                    }
                  }
                }
                &:nth-of-type(4) {  //郵便番号
                  dd {
                    &:nth-of-type(2) {
                      input {
                        width: 195px;
                      }
                    }
                  }
                }
                &:nth-of-type(5) {  //郵便番号
                  dd {
                    &:nth-of-type(2) {
                      input {
                        width: 100%;
                      }
                    }
                  }
                }
                &:nth-of-type(6) {  //郵便番号
                  dd {
                    &:nth-of-type(2) {
                      input {
                        width: 245px;
                      }
                    }
                  }
                }
                &:nth-of-type(7) {  //メールアドレス
                  dd {
                    &:nth-of-type(2) {
                      padding-bottom: 0;
                      >dl {
                        dt {
                          >input {
                            font-size: 16px;
                            width: 100%;
                            height: 46px;
                            background-color: #f0f0f0;
                            font-size: 16px;
                            color: #222;
                            padding-left: 17px;
                            letter-spacing: 1.5px;
                            -webkit-appearance: none;
                            border-radius: 0;
                            &:focus{
                              border: 1px solid #191919;
                              background-color: #fff;
                            }
                          }
                        }
                        dd {
                          letter-spacing: 1px;
                          &:first-of-type {
                            margin-top: 11px;
                            margin-bottom: 7px;
                          }
                          &:nth-of-type(2) {
                            >input {
                              font-size: 16px;
                              width: 100%;
                              height: 46px;
                              background-color: #f0f0f0;
                              font-size: 16px;
                              color: #222;
                              padding-left: 17px;
                              letter-spacing: 1.5px;
                              -webkit-appearance: none;
                              border-radius: 0;
                              &:focus{
                                border: 1px solid #191919;
                                background-color: #fff;
                              }
                            }
                          }
                          &:nth-of-type(3) {
                            font-size: 14px;
                            line-height: 24px;
                            color: #888888;
                            margin-top: 11px;
                            margin-bottom: 4px;
                            letter-spacing: 1px;
                            span {
                              width: 100%;
                              height: 100%;
                              background: none;
                              display: block;

                            }
                          }
                        }
                      }
                    }
                  }
                }
                &:nth-of-type(8) {
                  dd {
                    &:nth-of-type(2) {
                      padding-top: 23px;
                      padding-bottom: 8px;
                      >div {
                        label {
                          margin-left: 5px;
                          vertical-align: middle;
                        }
                        &:first-of-type {

                        }
                        &:nth-of-type(2) {
                        }
                        &:nth-of-type(3) {
                          margin-top: 8px;
                        }
                      }
                    }
                  }
                }
                &:nth-of-type(9) {
                  border-bottom: 1px solid #f1f1f1;
                  dd {
                    &:nth-of-type(2) {
                      width: 100%;
                      padding-bottom: 3px;
                      textarea {
                        padding-top: 13px;
                        padding-left: 16px;
                        width: 100%;
                        height: 195px;
                        background-color: #eee;
                        letter-spacing: 1.5px;
                        font-size: 16px;
                        color: #222;
                        resize: none;
                        -webkit-appearance: none;
                        border-radius: 0;
                        &:focus{
                          border: 1px solid #191919;
                          background-color: #fff;
                        }
                      }
                    }
                  }
                }
              }
              ul.contact-form1 {
                margin-top: 45px;
                width: 260px;
                height: 70px;
                margin: 0 auto;
                li.confirm {
                  background-image: none;
                  width: 100%;
                  height: 100%;
                  position: relative;
                  display: block;
                  background-color: #111;
                  opacity: 1;
                  text-decoration: none;
                  color: #fff;
                  -webkit-transition: .3s;
                  transition: .3s;
                  &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 0;
                    background: #fff;
                    -webkit-transform: scale(0, 1);
                    transform: scale(0, 1);
                  }
                  &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    display: block;
                    margin-top: -9px;
                    width: 18px;
                    height: 18px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url(../img/arrow_dubble_w.png);
                    background-size: 18px auto;
                  }
                  input {
                    position: relative;
                    z-index: 1;
                    display: block;
                    color: #fff;
                    width: 100%;
                    height: 100%;
                    font-weight: 700;
                    letter-spacing: .8px;
                  }
                }
              }
            }
          }
          #ContBox03 {
            padding: 53px 15px 72px;
            border-top: 1px solid #f1f1f1;
            h3 {
              width: 100%;
              margin: 0 auto;
              margin-bottom: 38px;
            }
            p {
              width: 100%;
              margin: 0 auto;
              letter-spacing: 1px;
            }
            >div {
              width: 100%;
              margin: 0 auto;
              h4 {
                font-size: 16px;
                font-weight: 700;
                border-bottom: 1px solid #333;
                margin-top: 32px;
                margin-bottom: 23px;
                padding-bottom: 10px;
              }
              >p {
                font-size: 14px;
                line-height: 28px;
                letter-spacing: .6px;
                span {
                  display: block;
                }
              }
              >ul {
                font-size: 14px;
                li {
                  margin-top: 4px;
                  letter-spacing: .6px;
                  &:first-of-type {
                    margin-top: 3px;
                  }
                }
              }
//              &:nth-of-type(8) {
//                >ul {
//                  li {
//                    &:nth-of-type(3) {
//                      span {
//                        margin-left: 10px;
//                      }
//                    }
//                  }
//                }
//              }
            }
          }
        }
      }
    }
  }

  /*
  お問い合わせ（確認画面）
  -------------------------------------*/
  #PageContact.pageConfirm {
    #Container {
      .innerBasic {
        #Main {
          #ContBox02 {
            margin-bottom: 133px;
            p {
              padding: 0 15px;
              text-align: justify;
              margin-top: 41px;
              margin-bottom: 34px;
              span {
              }
            }
            form {
              >dl {
                >dt {
                }
                >dd {
                  &:first-of-type {
                    &.required {
                    }
                  }
                  &:nth-of-type(2) {
                    padding-top: 12px;
                    padding-bottom: 10px;
                    letter-spacing: 1px;

                  }
                }
                &:first-of-type {  //お問い合わせ内容のラジオボタンの部分
                  dd {
                    &:nth-of-type(2) {
                      padding-top: 12px;
                      padding-bottom: 9px;

                    }
                  }
                }
                &:nth-of-type(5) {  //お問い合わせ内容のラジオボタンの部分
                  dd {
                    &:nth-of-type(2) {
                      padding-top: 3px;
                      padding-bottom: 5px;
                      line-height: 34px;
                    }
                  }
                }
                &:nth-of-type(7) {  //メールアドレス
                  dd {
                    &:nth-of-type(2) {
                      padding-bottom: 9px;
                    }
                  }
                }
                &:nth-of-type(8) {
                  dd {
                    &:nth-of-type(2) {
                      padding-top: 12px;
                      padding-bottom: 10px;
                    }
                  }
                }
                &:nth-of-type(9) {
                  dd {
                    &:nth-of-type(2) {
                      padding-bottom: 9px;
                    }
                  }
                }
              }
              ul.contact-form1 {
                li.confirm {
/*                  &:hover {
                    &:before {
                      -webkit-animation: hover-in-01 .3s forwards alternate, hover-out-01 .3s .3s forwards alternate;
                      animation: hover-in-01 .3s forwards alternate, hover-out-01 .3s .3s forwards alternate;
                    }
                  }*/
                }
                li.reset {
                  text-align: center;
                  margin-top: 25px;
                  input {
                    font-size: 14px;
                    color: #888888;
                    letter-spacing: 1px;
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  お問い合わせ（完了画面）
  -------------------------------------*/
  #PageContact.pageThanks {
    #Container {
      .innerBasic {
        #Main {
          #ContBox01 {
            margin-top: 54px;
            margin-bottom: -30px;
            h3 {
              margin-bottom: 38px;
            }
            p {
              font-size: 16px;
              text-align: justify;
              margin-bottom: 33px;
              span {
              }
              &:nth-of-type(2) {
                margin-top: 0;
                margin-bottom: 17px;
                a {
                  &:hover {
                    opacity: .6;
                  }
                }
              }
            }
            ul.aboutTopNav {
              li {
                width: 180px;
                height: 80px;
                margin: 0 auto;
                a {
                  width: 100%;
                  height: 100%;
                  span {
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 80px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }


}